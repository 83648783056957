import { FormGroup, FormControl } from "@angular/forms";

export class Default {
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId?: number;

    constructor(data?: Partial<Default>) {
        if (data) {
            if (data.hasOwnProperty('id')) {
                this.id = data.id;
            }

            if (data.hasOwnProperty('createdAt')) {
                this.createdAt = data.createdAt;
            }

            if (data.hasOwnProperty('updatedAt')) {
                this.updatedAt = data.updatedAt;
            }

            if (data.hasOwnProperty('userId')) {
                this.userId = data.userId;
            }
        }
    }

    getAsFormGroup(): FormGroup {
        return new FormGroup({
            id: new FormControl(this.id),
            createdAt: new FormControl(this.createdAt),
            updatedAt: new FormControl(this.updatedAt),
            userId: new FormControl(this.userId)
        });
    }
}