import dayjs from 'dayjs';
import { eachDayOfInterval } from 'date-fns';
import { SettingLocalStorageRepository } from '../../user-settings/repository/setting-local-storage-repository';

// For material Date picker
let dateFormat = SettingLocalStorageRepository.getDateFormat();
dateFormat = dateFormat?.replace(/dd/g, 'DD');

// For material Date picker
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: dateFormat,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const calculateDates = (from, to) => {
    const interval = {
        start: from,
        end: to,
    };

    return eachDayOfInterval(interval);
};

export const getDiffInDays = (date1: dayjs.Dayjs | Date, date2: dayjs.Dayjs | Date): number => {
    return dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day');
}

export class MomentRange {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;

    public static isOverlap(interval1: MomentRange, interval2: MomentRange): boolean {
        if (interval1.startDate.startOf('day').diff(interval2.endDate.startOf('day'), 'day') <= 0
            && interval2.startDate.startOf('day').diff(interval1.endDate.startOf('day'), 'day') <= 0)
            return true;
        return false;
    }

    public static getDatesInRange(range: MomentRange) {
        const daysList = calculateDates(range.startDate.toDate(), range.endDate.toDate());
        const chartDates = daysList.sort((a, b) => { return dayjs(a).diff(dayjs(b), 'd') });
        return chartDates;
    }

}

export const addSingleYear = (date: string) => {
    return getDateInYYYYMMDD(dayjs(date).add(1, 'year').toDate());
}

export const subtractSingleYear = (date: string) => {
    return getDateInYYYYMMDD(dayjs(date).subtract(1, 'year').toDate());
}

export const addWeek = (date: string) => {
    return getDateInYYYYMMDD(dayjs(date).add(7, 'day').toDate());
}

export const subtractWeek = (date: string) => {
    return getDateInYYYYMMDD(dayjs(date).subtract(7, 'day').toDate());
}

export const addSingleMonth = (date: string) => {
    return dayjs(date).add(30, 'day').toDate().getFullYear();
}

export const getFullYearFromNextYear = (date: string) => {
    return dayjs(date).add(1, 'year').toDate().getFullYear();
}

export const getFullYear = (date: string) => {
    return dayjs(date).toDate().getFullYear();
}

export const getNextMonth = (currentMonth: number) => {
    return currentMonth.toString().padStart(2, '0');
}

export const getDay = (date: number) => {
    return  dayjs().date(date).toDate().getDate().toString().padStart(2, '0');
}


// Function to get the date in the YYYYMMDD format
export const getDateInYYYYMMDD = (date: Date): string => {

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}